import React from "react";
import "./call-block.scss";
import cn from "classnames";

const CallBlock = ({ phone = [], workTime = [], className }) => {
	return (
		<section className={cn("call-block", className)}>
			<ul className="call-block__phone">
				{phone.map(item => (
					<li>
						<a rel="noopener noreferrer" href={`tel:${item}`}>
							{item}
						</a>
					</li>
				))}
			</ul>
			<ul className="call-block__work-time">
				{workTime.map(item => (
					<li>
						<p>{item}</p>
					</li>
				))}
			</ul>
		</section>
	);
};

export default CallBlock;
