import React from "react";
import "./phone.scss";
import CallBlock from "@components/call-block";

const Phone = () => {
	return (
		<section className="full-width phone">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Позвонить</h2>
					</div>
				</div>

				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-10">
						<div className="phone__call-block-wrapper">
							<h4>Владельцам ТК</h4>
							<CallBlock
								className="phone__call-block"
								phone={["+7 (843) 262-80-80"]}
								workTime={["многоканальный,", "с 8.00 до 19.00"]}
							/>
							<CallBlock
								className="phone__call-block"
								phone={["+7 (843) 519-60-39", "+7 (843) 238-62-33"]}
								workTime={[
									"пн-пт: с 8.30 до 19.00",
									"сб: с 8.30 до 15.00",
									"вс: выходной"
								]}
							/>
						</div>
					</div>
					<div className="col-xs-12 col-md-4 col-sm-offset-1 col-sm-10">
						<div className="phone__call-block-wrapper">
							<h4>По работе транспорта</h4>
							<CallBlock
								className="phone__call-block"
								phone={["+7 (843) 223-26-30"]}
								workTime={[]}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Phone;
